<template>
    <div>
    <ContentHeader title="Uang Duduk" url="/uang-duduk" subTitle="Tambah Uang Duduk" />
     <div class="content-header">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col">
                    <div class="card">
                        <div class="card-header row d-flex align-items-center">
                            <h3 class="card-title col-6">Tambah Uang Duduk</h3>
                        </div>
                        <div class="card-body row">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-8">
                                <form class="pl-2" @submit.prevent="prosesUangDuduk">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="nomor_rekam_medis">Nama Dokter <span class="text-danger">*</span></label>
                                                <multiselect 
                                                  v-model="dokter_select_value"
                                                  deselect-label="Can't remove this value"
                                                  track-by="nama_lengkap"
                                                  label="nama_lengkap"
                                                  placeholder=""
                                                  :custom-label="customSelectDokter"
                                                  :options="dataDokter"
                                                  :allow-empty="false"
                                                  @update:model-value="dokter_select_change"
                                                >
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Tanggal Kerja <span class="text-danger">*</span></label>
                                                <input type="date" name="" class="form-control" required="" v-model="uang_duduk.tanggal_kerja">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Jam Kerja <span class="text-danger">*</span></label>
                                                <select class="custom-select form-control" required="" v-model="uang_duduk.jam_kerja">
                                                    <option disabled="">- Pilih Jam Kerja -</option>
                                                    <option value="09:00 s/d 15:00">09:00 s/d 15:00</option>
                                                    <option value="1 Shift">1 Shift</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Uang Duduk <span class="text-danger">*</span></label>
                                                <input type="number" class="form-control" v-model="uang_duduk.nominal" required="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Jumlah Pasien <span class="text-danger">*</span></label>
                                                <input type="number" class="form-control" required="" v-model="uang_duduk.jumlah_pasien">
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
                                        <div>Submit</div>   
                                        <div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
                                          <span class="sr-only">Loading...</span>
                                        </div>
                                    </button>
                                </form>
                                <div class="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, onMounted, computed, reactive } from 'vue'
import store from '@/store'
import router from '@/router'
import axios from 'axios'
import Swal from 'sweetalert2'

export default{
    components: {
        ContentHeader,
        Multiselect
    },
    setup(){
        const user = computed(() => store.getters['auth/user'])
        const uang_duduk = reactive({
            'cabang_id' : user.value.cabang_id,
            'dokter_id' : '',
            'tanggal_kerja' : '',
            'jam_kerja' : '',
            'nominal' : '',
            'jumlah_pasien' : ''
        })
        
        const loading = ref(false)

        // DOKTER
        const dataDokter = ref([])
        const dokter_select_value = ref('')
        const cek_data_dokter = ref(false)

        const getDokter = async () => {
            let response = await axios.get(`api/karyawan/getAllWhereJabatan/${user.value.cabang_id}/dokter`)

            if (response.data == 'kosong') {
                cek_data_dokter.value = true
                dataDokter.value = null
            } else {
                cek_data_dokter.value = false
                dataDokter.value = response.data
            }
        }

        const dokter_select_change = () => {
            uang_duduk.dokter_id = dokter_select_value.value.id
        }

        const customSelectDokter = ({ nama_lengkap }) => {
          return `Dr. ${nama_lengkap}`
        }
        // END DOKTER


        const prosesUangDuduk = () => {
            loading.value = true
            console.log(uang_duduk)
            axios.post('api/uang_duduk/create', uang_duduk)
            .then((response) => {
                Swal.fire({
                  title: 'Berhasil!',
                  text: 'Berhasil Menanbahkan Data',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500
                })  
                loading.value = false
                console.log(response)
                router.push('/uang-duduk')
            })
            .catch((error) => {
                Swal.fire({
                  title: 'Gagal!',
                  text: 'Gagal Menanbahkan Data',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: 1500
                })  
                loading.value = false
                console.log(error)
            })
        }

        onMounted(() => {
            getDokter()
        })

        return { uang_duduk, prosesUangDuduk, loading, dataDokter, dokter_select_value, dokter_select_change, customSelectDokter }
    }
}
</script>

<style>
    
</style>